import React, { useMemo } from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import LayoutNoStripe from "../../components/layoutNoStripe"
import SEO from "../../components/seo"
import newfixture from "../../fixtures/workshops/info"
import { Article, ArticleHeader, ArticleSection } from "../../styledComponents/article";
import Level1Img from "../../images/workshops/quantum-touch/Level-1-becoming-energized-min.png";
import Level2Img from "../../images/workshops/quantum-touch/Level-2-becoming-supercharged-min.png";
import Level3Img from "../../images/workshops/quantum-touch/Level-3-becoming-whole-min.png";
import Level4Img from "../../images/workshops/quantum-touch/Level-4-becoming-heart-conscious-min.png";
import Level5Img from "../../images/workshops/quantum-touch/Level-5-becoming-miraculous-min.png";
import { StyledProductItem, StyledProductItemWrapper } from "../../styledComponents/imageTitleExcerptList";
import { StyledAnchorLink, StyledButtonAsLink, StyledLinkButton } from "../../styledComponents/button";
import { Link } from "gatsby";
import { EventListSection } from "../../styledComponents/eventList"
import EventList from "../../components/EventList"
import Events from "../../fixtures/workshops/all"
import { SectionHeading } from "../../styledComponents/section"
import RelatedQtArticles from "../../components/QuantumTouch/relatedQuantumTouchAricles"


const Heading = ({ level = 1, location, children, offScreen }) => (
    <SectionHeading as={`h${level}`} level={level} location={location} offScreen={offScreen} marginBottom="1.5rem">
        {children}
    </SectionHeading>
)
const levelsMap = {
    'qt-level-1': 'Quantum-Touch Level 1: Becoming Energized',
    'qt-level-2': 'Quantum-Touch Level 2: Becoming Supercharged',
    'qt-level-3': 'Quantum-Touch Level 3: Becoming Whole',
    'qt-level-4': 'Quantum-Touch Level 4: Becoming Heart-Conscious',
    'qt-level-5': 'Quantum-Touch Level 5: Becoming Miraculousxx ',
};

const imageMap = {
    'qt-level-1': Level1Img,
    'qt-level-2': Level2Img,
    'qt-level-3': Level3Img,
    'qt-level-4': Level4Img,
    'qt-level-5': Level5Img
}

const QuantumTouchWorkshopList = ({ data, location }) => {
    const pageUrl = `https://${process.env.GATSBY_DOMAIN_NAME}/quantum-touch/an-interview-with-richard-gordon-founder-of-quantum-touch`
    let allevents = Events
    const groupedLevels = useMemo(() => {
        return newfixture.reduce((acc, event) => {
            if (!event || !event.eventType) return acc;

            const levelName = levelsMap[event.eventType];
            if (!levelName) return acc;

            if (!acc[levelName]) acc[levelName] = [];

            acc[levelName].push({
                title: event.title,
                uri: event.uri,
                excerpt: event.excerpt,
                eventType: event.eventType, // Fix: include this
            });

            return acc;
        }, {
            'Level 1': [],
            'Level 2': [],
            'Level 3': [],
            'Level 4': [],
            'Level 5': [],
        });
    }, []);



    return (
        <LayoutNoStripe displayNavBorder location={location} hideNewsletterSignUp>
            <SEO
                title="Quantum-Touch Workshop Levels Explained - New Names & Descriptions"
                description="Explore the complete list of Quantum-Touch workshops with their updated level names. Learn what each level offers, from beginner to advanced energy healing techniques, guided by expert instructor Karina Grant."
                url="/quantum-touch/"
                image="/images/quantum-touch/list-of-qt-workshops.png"
            />
            <Article itemScope itemType="http://schema.org/Article">
                <ArticleHeader className="article-header">
                    <h1 itemProp="headline">Quantum-Touch Workshop Levels Explained - New Names & Descriptions</h1>
                    <p><strong>Discover the transformative power of energy healing through the newly renamed Quantum-Touch workshops.</strong></p>
                    <p>Whether you're just starting or ready to deepen your skills, each level offers a unique path for personal growth and healing.</p>
                    <p>Begin with <strong>Level 1: Becoming Energized</strong>, the foundation of all Quantum-Touch techniques. From there, explore advanced workshops—Level <strong>2: Becoming Supercharged, Level 3: Becoming Whole, Level 4: Becoming Heart-Conscious</strong>, and <strong>Level 5: Becoming Miraculous</strong> — designed to expand your awareness, enhance your healing abilities, and help you create lasting change in every area of your life.</p>
                </ArticleHeader>
                <ArticleSection className="workshopinfo">
                    {Object.entries(groupedLevels)
                        .filter(([, events]) => events.length > 0) // ⬅️ Only render sections with events
                        .map(([level, events]) => (
                            <StyledProductItem key={level}>
                                {events.map((event, i) => (
                                    <StyledProductItemWrapper key={i}>
                                        {imageMap[event.eventType] && (
                                            <img src={imageMap[event.eventType]} alt={event.title} />
                                        )}
                                        <div className="text-block">
                                            <h2>{event.title}</h2>
                                            <p>{parse(event.excerpt)}</p>
                                            {/* <Link href={event.uri}>Read more about {event.title}</Link> */}
                                        </div>
                                    </StyledProductItemWrapper>
                                ))}
                            </StyledProductItem>
                        ))}
                    
                </ArticleSection>
                <RelatedQtArticles data={data.relatedArticles} />
                <StyledProductItem>
                        <p><strong>Please note</strong>: You must complete a live Quantum-Touch Level 1 Workshop (in-person or virtual) with a Certified Instructor before attending any other Quantum-Touch workshop. Once you've taken Level 1, you're welcome to join Level 3, Level 5, or any advanced workshop in any order.</p>
                    </StyledProductItem>
            </Article>


            <EventListSection className="event-list">
                <Heading level={2}>Upcoming workshops</Heading>
                <EventList workshops={allevents} eventType="all" />
            </EventListSection>
        </LayoutNoStripe >
    )
}

export default QuantumTouchWorkshopList

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allWpInformation(filter: {tags: {nodes: {elemMatch: {slug: {eq: "quantum-touch"}}}}, slug: {eq: "what-is-quantum-touch-energy-healing"}}) {
      nodes {
        title
        excerpt
        content
        uri
        slug
        date(formatString: "MMMM DD, YYYY")
        dateGmt
        featuredImage {
          node {
            localFile {
              publicURL
            }
          }
        }
      }
    }


    relatedArticles : allWpInformation(filter: {tags: {nodes: {elemMatch: {slug: {eq: "quantum-touch"}}}}}) {
      nodes {
        title
        slug
        uri
      }
    }
  }
`


