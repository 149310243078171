import styled from "styled-components"
import breakpoints from "./breakpoints"
import { bold18, bold24, bold28, regular18 } from "./typography"


// ✅ This should exist in styledComponents/imageTitleExcerptList.js
export const StyledProductItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media screen and (min-width: ${breakpoints.md}px) {
    flex-direction: row;
    align-items: flex-start;
    gap: 2rem;
    margin-bottom: 4rem;
  }

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    @media screen and (min-width: ${breakpoints.sm}px) {
        max-width: auto;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
        max-width: 400px;
        margin-bottom: 2.4rem;
    }
  }

  .text-block {
    padding-left: 0;
    @media screen and (min-width: ${breakpoints.md}px) {
      padding-left: 1.5rem;
    }

    h2 {
      margin: 0 0 1.2rem 0;
      ${bold18}
      @media screen and (min-width: ${breakpoints.md}px) {
        ${bold24}
        margin-bottom: 1.2rem;
      }
      @media screen and (min-width: ${breakpoints.l}px) {
        // ${bold24}
        margin-bottom: 1.2rem;
      }
    }

    p {
      margin: 0 0 1rem 0;
    }
  }
`;

export const StyledProductItem = styled.div`
  grid-column: 1 / 5;
  width: 100%;
  @media screen and (min-width: ${breakpoints.sm}px) {
    grid-column: 1 / 7;
    margin: 0;
    padding: 0;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column: 1 / 13;
  }

  @media screen and (min-width: ${breakpoints.l}px) {
    grid-column: 1 / 12;
  }
`