const fixture = [
  {
    "id": "level1==",
    "title": "Quantum-Touch Level 1: Becoming Energized",
    "uri": "/events/quantum-touch/",
    "imageSrc": "../images/workshops/quantum-touch/Level-1-becoming-energized-min.png",
    "eventType": "qt-level-1",
    "excerpt": `<p>The Quantum-Touch Level 1: Becoming Energized Workshop introduces essential energy healing techniques. </p><p>It's designed for both individuals seeking self-care methods and healthcare professionals aiming to enhance their skills. Participants will learn a variety of Quantum-Touch techniques applicable to personal well-being and assisting others.</p>`,
    
  },
  {
    "id": "level2==",
    "title": "Quantum-Touch Level 2: Becoming Supercharged",
    "uri": "/events/quantum-touch",
    "imageSrc": "../images/workshops/quantum-touch/Level-2-becoming-supercharged-min.png",
    "eventType": "qt-level-2",
    "excerpt": `<p>Advance your energy healing practice with the Quantum-Touch Level 2: Becoming Supercharged Workshop.</p><p> This course introduces advanced techniques to deepen your connection to heart energy, enhance intuitive abilities, and promote profound healing for yourself and others. Experience a new paradigm in energy work that expands your potential.</p>`,
    
  },
  {
    "id": "level3==",
    "title": "Quantum-Touch Level 3",
    "uri": "/events/quantum-touch",
    "imageSrc": "../images/workshops/quantum-touch/Level-3-becoming-whole-min.png",
    "eventType": "qt-level-3",
    "excerpt": `<p>The Quantum-Touch Level 3: Becoming Whole Workshop guides you through a seven-step process to identify and release the emotional roots of physical and emotional discomfort. This journey fosters profound forgiveness, self-love, and gratitude, culminating in the experience of your infinitely loving nature. By addressing emotional health, you pave the way for a more fulfilling and joyful life.</p><p><strong>Formerly Self-Created Health</strong></p>`,
    
  },
  {
    "id": "level4==",
    "title": "Quantum-Touch Level 4",
    "uri": "/events/quantum-touch",
    "imageSrc": "../images/workshops/quantum-touch/Level-1-becoming-energized-min.png",
    "eventType": "qt-level-4",
    "excerpt": `<p>Elevate your energy healing abilities with the Quantum-Touch Level 4: Becoming Heart-Conscious Workshop. </p><p>This course introduces advanced techniques to deepen your heart connection, enhance intuitive skills, and promote profound healing for yourself and others. Experience a transformative journey that expands your understanding and application of Life Force Energy.​</p><p><strong>Formerly Level 2</strong></p>`,
    
  },
  {
    "id": "level5==",
    "title": "Quantum-Touch Level 5 - Formerly Manifesting Miracles",
    "uri": "/events/quantum-touch",
    "imageSrc": "../images/workshops/quantum-touch/Level-1-becoming-energized-min.png",
    "eventType": "qt-level-5",
    "excerpt": "<p>The Quantum-Touch Level 5: Becoming Miraculous Workshop empowers you to manifest desired changes in areas like abundance, career, and relationships by teaching you to harness and direct Life Force Energy toward your goals.</p><p><strong>Formerly known as Manifesting Miracles</strong></p>",
    
  },
  
]
export default fixture

// Year	Clocks go forward	Clocks go back
// 2024	31 March	27 October
// 2025	30 March	26 October
// 2026	29 March	25 October